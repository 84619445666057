// Variables defined here are used throughout the site

// BREAKPOINTS

// USAGE: susy-breakpoint($query, $layout, $no-query)
//        @include susy-breakpoint(30em, 8) { ... }
//        currently, we are not changing #columns, so usage is Breakpoint standard
//        @include breakpoint($medium) { ... }

$layout: 100rem;        // 1600  // point at which max-width kicks in
$largest: 90rem;        // 1440
$large: 80rem;          // 1280
$full: 64rem;           // 1024
$medium: 56rem;         // 896
$medium-small: 40rem;   // 640
$small: 30rem;          // 480
$smallest: 20rem;       // 320   // smallest size we want to be bothered with

// up to a size
// @include breakpoint(max-width $max-medium) { ... }
// use the strip-unit function to avoid stupid math errors
$max-small: ((strip-unit($small) * 16px) - 1);
$max-medium-small: ((strip-unit($medium-small) * 16px) - 1);
$max-medium: ((strip-unit($medium) * 16px) - 1);
$max-layout: ((strip-unit($layout) * 16px) - 1);

// define min- & max-widths for page layout
$maxwidth: $largest;  // 1440 max-width for full screen page width