//-- Basic Form Styling --//

input[type="input"],
input[type="text"],
input[type="password"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="month"],
input[type="week"],
input[type="email"],
input[type="number"],
input[type="search"],
input[type="tel"],
input[type="time"],
input[type="url"],
textarea {
  -webkit-appearance: none;
  border-radius: 0;
  background-color: #fff;
  font-family: inherit;
  border: 1px solid $grey;
  box-shadow: none;
  color: $softblack;
  display: block;
  font-size: 1rem;
  margin: 0 0 1em 0;
  padding: 0.5em;
  height: 2.375em;
  width: 100%;
  transition: box-shadow 0.45s, border-color 0.45s ease-in-out; // this controls the glow effect on focus

  #{$targeted} {
    box-shadow: 0 0 5px $bluegrey;
    border-color: $grey;
    background: #fff;
    outline: none;
  }

  &[disabled] {
    background-color: $grey;
  }
}

input[type="file"],
input[type="checkbox"],
input[type="radio"],
select {
  margin: 0 0  1em 0;
}

input[type="file"] {
  width: 100%;
  font-size: smaller;
}

input[type="checkbox"],
input[type="radio"] {
  margin-right: 0.5em;
}

input[type="checkbox"] {
  vertical-align: text-top;
}

textarea {
  min-height: 8rem;

  &[name="message"] {
    min-height: 16rem;
  }
}

form {
  font-size: 1rem;
  margin: 0 0 1em 0;
  position: relative;
  padding: 0;

  small {
    text-transform: capitalize;
    color: $softblack;
  }

  input[type="button"],
  input[type="submit"],
  button,
  .button {
    float: right;
    @include clearfix;
  }

  legend,
  caption,
  thead tr {
    font-weight: 400;
  }

  label {
    margin: 0px;
    line-height: 1.5;
    display: inline-block;
    color: $softblack;
    cursor: inherit;
  }

  select {
    width: 100%;
    display: block;
  }

  fieldset {
    clear: both;

    legend {
      font-size: 1.125rem;
      margin: 0 0 0 -0.5em;
      line-height: 1.6;
      background: transparent;
      padding: 0 0.5em;
      color: $blue;
    }
  }

  .note {
    font-size: small;
    color: $lightblack;
    margin-top: -0.875rem;
  }

  .c-form-group {
    position: relative;
  }

  label.error {
    position: absolute;
    top: 2px;
    right: 9px;

    &#captcha_code-error {
      top: 50%;
    }
  }

  .c-form-verification {
    position: relative;
  }

  .c-captcha {
    margin: 0.5rem 0;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    line-height: 1;

    a {
      font-size: small;
      padding-left: 0.5rem;
    }
  }
}

// all errors
.error {
  color: $red;
  font-weight: 600;
  background-color: $palered;
  padding: 2px 0.5rem;
  border: 1px solid;
}

// close buttons should hide until the response is vibisble
// script switches states
button#responseClose {
  display: none;
}

// the response box
// script hides form and shows this
#formResponse {
  position: relative;

  p {
    margin-bottom: 0;

    &:first-child:not(.error) {
      @extend h2;
    }
  }

  em {
    font-size: smaller;
  }

  // the error message additional note
  span.hint {
    background-color: $truewhite;
    display: block;
    padding: 0.25rem 0.5rem;
    font-size: 0.75rem;
  }
}