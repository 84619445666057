/* Branding */
// General brand coloring and spacing - universal classes go in _universal!

// Page coloring
body {
  background-color: $midblack;
  color: $white;
  font-size: 100%;
  line-height: 1.5;
  font-smooth: always;
  font-family: 'Source Sans Pro', Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  text-rendering: optimizeSpeed;
  font-smoothing: antialiased;
}

header, main, footer {
  min-width: $smallest;
  @include clearfix;
}