// GENERAL VARIABLES

// min-width of mobile page width
$minwidth: $smallest;

// set up a few constants
$vertical-rhythm: 1.375rem;
$lr: 5%;

// link variables to set link/visited/hover/active/target easily, for any element
// see button.scss for example
$linked: '&:link, &:visited';
$targeted: '&:hover, &:active, &:focus';
// .class {
//   #{$targeted} {
//     styles
//   }
// }
