//-- Legal pages --//

article.legal {

  aside {
    background-color: $grey;
    border: 1px solid $lightblack;
    padding: 0.5rem;
    margin: 1rem 0 0 0;

    a {
      #{$targeted} {
        color: $truewhite;
      }
    }
  }

  @include breakpoint($full) {
    display: flex;
    flex-wrap: wrap;

    h1, p {
      flex: 1 0 100%;
    }

    section {
      flex: 1 1 72%;
    }

    aside {
      flex: 0 1 25%;
      margin: 0 0 0 2%;
    }
  }
}