//--- Gallery carousel ---//

.carousel.gallery {

  .carousel-seat {

    @include breakpoint($large) {

      img {
        max-width: 1000px;
        max-height: 1000px;
      }
    }

    &.is-active {
      order: 1;

      img {
        box-shadow: 0px 0px 12px rgba($truewhite, 0.6), 0px 0px 1px $truewhite;
        border: 1px solid $softblack;
      }
    }
  }
}