// --- MENU - HOME ---//

body.home {

  .c-mainmenu .wrapper {

    @include breakpoint($medium) {
      display: flex;
      flex-wrap: wrap;
      align-content: center;
      justify-content: center;
      align-items: center;

      @include breakpoint($layout) {
        padding: $vertical-rhythm 0;
      }

      // size & place the images for flexbox
      #carved {
        order: 2;
        align-self: flex-end;
        flex: 0 1 33%;
      }
      #inhabitants {
        order: 10;
        align-self: flex-start;
        flex: 0 1 26%;
        left: -1%;
      }
      #landscapes {
        order: 4;
        flex: 0 1 33%;
        top: 4%;
      }
      #memorial {
        order: 9;
        align-self: flex-start;
        flex: 0 1 24%;
      }
      #metal {
        order: 6;
        align-self: flex-end;
        flex: 0 1 26%;
      }
      #motion {
        order: 1;
        align-self: flex-end;
        flex: 0 1 19%;
        left: 2%
      }
      #sierras {
        order: 3;
        align-self: flex-end;
        flex: 0 1 21%;
        bottom: -11%;
        left: -2%;
      }
      #silt {
        order: 7;
        align-self: flex-start;
        flex: 0 1 26%;
        top: -3%;
        left: 2%;
      }
      #small {
        order: 0;
        align-self: flex-end;
        flex: 0 1 20%;
        bottom: -4%;
        left: 4%
      }
      #structures {
        order: 5;
        flex: 0 1 40%;
      }
      #textures {
        order: 8;
        align-self: flex-start;
        flex: 0 1 19%;
        left: 1%;
      }

      // @include breakpoint($layout) {
      //   #small {
      //     bottom: -2%;
      //   }
      //   #silt {
      //     top: 0;
      //   }
      //   #sierras {
      //     bottom: -8%;
      //   }
      // }

      @supports (display: grid) {
        display: grid;
        grid-template-columns: repeat(24, 1fr);
        grid-gap: 0.125rem;
        align-items: start;

        // grid areas
        #carved {
          grid-area: 2 / 11 / 7 / 18;
        }
        #inhabitants {
          grid-area: 13 / 18 / 17 / 24;
          left: auto;
        }
        #landscapes {
          grid-area: 8 / 1 / 13 / 8;
          top: auto;
        }
        #memorial {
          grid-area: 14 / 12 / 20 / 18;
        }
        #metal {
          grid-area: 9 / 18 / 13 / 24;
          top: auto;
          left: auto;
        }
        #motion {
          grid-area: 1 / 7 / 7 / 11;
          left: auto;
        }
        #sierras {
          grid-area: 4 / 18 / 9 / 23;
          bottom: auto;
          left: auto;
        }
        #silt {
          grid-area: 13 / 2 / 17 / 8;
          top: auto;
          left: auto;
        }
        #small {
          grid-area: 5 / 2 / 8 / 7;
          bottom: auto;
          left: auto;
        }
        #structures {
          grid-area: 7 / 8 / 14 / 18;
          // center it, since the image isn't the full height
          margin: 0.625rem 0;
        }
        #textures {
          grid-area: 14 / 8 / 20 / 12;
          left: auto;
        }
      }
    }
  }
}