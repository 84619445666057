//--- Branding for text elements ---//

// Basic typography rules
// All font sizes should be declared in rem!

  // REM Guide
  // ---------
  // 1rem = 16px

  // 0.5 = 8
  // 0.625 = 10
  // 0.75 = 12
  // 0.875 = 14
  // 1 = 16
  // 1.125 = 18
  // 1.25 = 20
  // 1.375 = 22
  // 1.5 = 24
  // 1.625 = 26
  // 1.75 = 28
  // 1.875 = 30
  // 2 = 32
  // 2.125 = 34
  // 2.25 = 36
  // 2.375 = 38
  // 2.5 = 40
  // 2.625 = 42
  // 2.75 = 44
  // 2.875 = 46
  // 3 = 48
  // 3.125 = 50
  // 3.25 = 52
  // etc! :)

body > * {
  font-size: 1rem;
}

h1 { font-size: 2.625rem; }
h2 { font-size: 1.625rem; }
h3 { font-size: 1.25rem; }
h4,h5,h6 { font-size: 1rem; }

h1, h2 {
  font-weight: normal;
  line-height: 1.2;
}

h1 {
  text-transform: uppercase;
  color: $blue;

  a {
    #{$linked} {
      color: $blue;
    }
  }
}

h2 {
  color: $darkblue;

  a {
    #{$linked} {
      color: $darkblue;
    }
    #{$targeted} {
      color: $blue;
    }
  }
}

h3, h4 {
  font-weight: 700;
  line-height: 1.4;
}

h5, h6 {
  font-weight: 700;
}

h3, h4, h5, h6 {
  color: $softblack;

  a {
    #{$linked} {
      color: $softblack;
    }
    #{$targeted} {
      color: $blue;
    }
  }
}

h1, h2, h3, h4, h5, h6 {
  margin: 0 0 1rem 0;
  text-rendering: optimizeLegibility;
}

p, ul, ol, dl, address {
  margin: 0 0 0.875rem 0;
}

@include breakpoint($full) {
  p, ul, ol, dl, address {
    margin: 0 0 1rem 0;
  }
}

p, ul, ol, dl, address {
  &:last-child:not(.note) { margin-bottom: 0; }
}

strong { font-weight: 600; }
em { font-style: italic; }

// other text-related elements
ins {
  background: $bluegrey;
  color: $black;
  text-decoration: none;
}

mark {
  background: $blue;
  color: $white;
  font-style: italic;
  font-weight: 700;
}

hr {
  border: 0;
  border-top: 1px solid $softblack;
  display: block;
  height: 1px;
  margin: 0.375rem 0 0.625rem;
  padding: 0;
}

// selected text
::selection {
  background-color: $yellow;
  color: $black;
}