//--- Header ---//

header {
  background: $blue;
  border-bottom: 4px solid $bluegrey;

  @include positioning;

  a {
    display: inline-block;
  }

  img {
    max-width: 150px;

    @include breakpoint($small) {
      max-width: 200px;
    }
  }
}
