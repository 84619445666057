//-- Menu - Buttons --//

.menu-buttons {
  position: absolute;
  top: -4.125rem;
  right: $lr;
  z-index: 999;
  display: block;
  overflow: hidden;

  @include breakpoint($layout) {
    right: 0;
  }

  #{$linked} {
    color: $grey;
  }

  #{$targeted} {
    color: $truewhite;
  }

  // this needs to correspond to the svg sizes
  height: 2.375rem;
  width: 2.375rem;

  // this need breakpoints
  svg.icon {
    width: 2.375rem;
    height: 2.375rem;
  }

  svg.interface-menu {
    fill: $white;

    #{$targeted} {
      fill: $truewhite;
    }
  }

  body.home & {
    display: none;
  }

  // header changes sizes
  @include breakpoint($small) {
    top: -4.75rem;
  }

  // menu changes locations,
  // but position is only due to padding changes on .open
  @include breakpoint($layout) {
    .c-mainmenu.open & {
      top: -6.125rem;
    }
  }

  // the menu icon was the only icon below $medium
  // now this appiles to all breakpoints
  // @include breakpoint(max-width $max-medium) {
    background-color: $blue;

    svg.icon {
      border: 5px solid $blue;
    }
  // }

  // keep for refernce - settings for other icons
  // @include breakpoint((strip-unit($medium) * 16px) $max-layout) {
  //   background-color: $black;
  //   top: 0;
  //   right: 2.625rem;

  //   svg.icon {
  //     border: 5px solid $black;
  //   }
  // }

  // @include breakpoint($layout) {
  //   background-color: $white;
  //   top: 1.75rem;
  //   right: -3.125rem;

  //   svg.icon {
  //     border: 5px solid $white;
  //   }

  //   #{$linked} {
  //     color: $midblack;
  //   }

  //   #{$targeted} {
  //     background-color: $grey;
  //     color: $softblack;

  //     svg.icon {
  //       border: 5px solid $grey;
  //     }
  //   }
  // }
}


// keep for reference: svg "sprite" for multiple icons in one button
// <a href="#" class="menu-buttons" title="Menu">
//   <span class="icon-wrapper">
//     <svg viewBox="0 0 100 100" class="icon interface-menu">
//       <use xlink:href="mrp.svg#interface-menu"></use>
//     </svg>
//     <svg viewBox="0 0 100 100" class="icon interface-enlarge">
//       <use xlink:href="mrp.svg#interface-enlarge"></use>
//     </svg>
//     <svg viewBox="0 0 100 100" class="icon interface-shrink">
//       <use xlink:href="mrp.svg#interface-shrink"></use>
//     </svg>
//   </span>
// </a>
.icon-wrapper {
  width: 2.375rem;
  display: block;
  position: absolute;
  left: 0rem;

  // this is hamburger - mobile always
  top: 0rem;

  // @include breakpoint($medium) {
  //   // this is default
  //   top: -2.375rem;

  //   // this is active
  //   .menu-buttons.active & {
  //     top: -4.65rem;
  //   }
  // }
}