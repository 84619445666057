//--- Navigation arrows for the galleries ---//

.controls {
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: stretch;
  opacity: 0.25;

  #{$targeted} {
    opacity: 1;
  }

  a {
    color: $softblack;
    display: flex;
    align-items: center;

    #{$targeted} {
      color: $softblack;
      cursor: pointer;
    }
  }

  svg.icon {
    width: 10vw;
    height: 10vw;

    @include breakpoint($medium-small){
      width: 7vw;
      height: 7vw;
    }

    @include breakpoint($medium){
      width: 5vw;
      height: 5vw;
    }
  }

  &.gallery {
    z-index: 999;

    a {
      height: 80%;
    }
  }

  &.thumbs {
    z-index: 9;

    a {
      height: 82%;
      margin-top: -2.75%;
    }
  }
}