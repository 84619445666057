//--- Thumbs carousel ---//

.carousel.thumbs {
  flex-wrap: nowrap;
  margin: 0 6.375%;
  z-index: 999;

  @include breakpoint($medium) {
    margin: 0 3.6%;
  }

  .carousel-seat {
    order: 1;
    margin: 0.125em 1%;
    overflow: hidden;
    border: 1px solid $softblack;
    opacity: 0.42;

    // js handles height.
    flex: 0 0 48%;
    max-width: 48%;

    #{$targeted} {
      cursor: pointer;
      opacity: 0.78;
    }

    @include breakpoint($small) {
      flex-basis: 31%;
      max-width: 31%;
      margin: 0.375em 1%;
    }
    @include breakpoint($medium) {
      flex-basis: 23%;
      max-width: 23%;
    }
    @include breakpoint($full + 1rem) {
      flex-basis: 18%;
      max-width: 18%;
    }
    @include breakpoint($large) {
      margin: 0.625em 1%;
    }
    @include breakpoint($largest) {
      flex-basis: 14.5%;
      max-width: 14.5%;
    }

    img {
      max-width: 200%;
      margin-left: -50%;
      margin-top: -20%;
    }

    &.is-active {
      outline: 5px solid rgba($truewhite, 0.86);
      box-shadow: 0px 0px 10px $black;
      z-index: 99;
      opacity: 1;
    }

    &.offscreen {
      order: 2;           // resort the thumbs for paging - put these at the end
      visibility: hidden; // because sometimes they aren't -actually- off the screen
    }
  }
}