//-- Carousel Pagination --//

.pagination {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  color: $lightblack;
  font-size: 0.825rem;

  // Use if the pagination needs to be linked.
  // @include list-clean;

  // li {
  //   list-style-position: inside;
  //   margin: ($vertical-rhythm / 6);
  //   width: 1rem;
  //   height: 1rem;

  //   #{$targeted}{
  //     color: $black;
  //     cursor: pointer;
  //   }
  // }
}