//--- Main ---//

main {
  position: relative;
  background-color: $softblack;
  background: url('../img/bkg-texture2.jpg') 0 0 repeat-x, url('../img/bkg-texture.jpg') 0 0 repeat;
  color: $white;
  border-bottom: 2px solid $black;

  @include breakpoint((strip-unit($medium) * 16px) $max-layout) {
    display: flex;
    flex-flow: row-reverse nowrap;
    align-content: center;
    align-items: stretch;
    justify-content: flex-start;
  }
}