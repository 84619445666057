//--- Base styling for Links ---//

%no-underline {
  text-decoration: none;
}

a {

  #{$linked} {
    color: $lightblue;
    @extend %no-underline;
  }

  #{$targeted} {
    color: $grey;
    text-decoration: underline;
  }

  &:focus {
    // edited re: http://a11yproject.com/posts/never-remove-css-outlines
    outline-width: 0;

    img, svg {
      outline: 1px dotted;
    }
  }

  &:before,
  &:after {

    #{$targeted} {
      @extend %no-underline;
    }
  }
}

nav a {

  #{$targeted} {
    @extend %no-underline;
  }
}