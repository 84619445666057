//--- Menu - Links ---//

.l-gallery {
  position: relative;
  padding: ($vertical-rhythm / 3);
  color: $white;

  &.active {
    background-color: $lightblack;
    box-shadow: 0px 0px 4px rgba($white,0.25);
  }

  // layout from 320 to 800, all pages
  @include breakpoint(max-width $max-medium) {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-content: stretch;
    align-items: center;
    margin: ($vertical-rhythm / 2) 0;
    box-shadow: 0px 0px 4px rgba($black, 0.6);
    border-width: 1px;
    border-style: solid;
    border-color: $black;
  }

  // thin menu - 320 to 480, all pages
  @include breakpoint(max-width $max-medium-small) {
    img {
      width: 25%;
    }

    p {
      width: 66%;
    }

    &.tall {
      width: 100% !important;

      img {
        width: 17.5%;
        margin-left: 4%;
      }
    }
  }

  // wide menu - 480 to 800, all pages
  @include breakpoint((strip-unit($medium-small) * 16px) $max-medium) {

    &:nth-child(2n) {
      margin-right: 4%;
    }

    img {
      width: 50%;
    }

    p {
      width: 48%;
    }

    &.tall {

      img {
        width: 35%;
        margin-left: 8%;
      }

      p {
        margin-left: 3%;
      }
    }

    // max-[size]
    &.wide img,
    &.square img {
      max-width: 130px;
    }

    &.tall img {
      max-height: 130px;
      width: auto;
    }
  }

  // layout above 800, all pages
  @include breakpoint($medium) {
    display: block;
    margin: 0 auto;
  }

  @include breakpoint($layout) {
    margin: 0;
  }

  // all images, all pages, all sizes
  img {
    border: 2px solid $grey;
  }

  // styles when NOT on HOME
  body:not(.home) & {

    // hide links on mobile when not active
    @include breakpoint(max-width $max-medium) {
      display: none;
    }

    // match size of horizontal imgs
    &.tall {
      width: 72%;
    }

    // desktop when not active has no text
    @include breakpoint($medium) {

      p {
        display: none;
      }
    }

    @include breakpoint($layout) {

    }
  } // end styles when NOT on HOME

  // targeted, all pages
  #{$targeted} {
    color: $white;

    @include breakpoint(max-width $max-medium) {
      background-color: desaturate(lighten($softblack, 9%), 18%);
      border-color: $black;
      box-shadow: 0px 0px 30px rgba($black, 0.8);
    }
  }
}