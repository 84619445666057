//--- Menu - Links - active ---//

// ACTIVE STATE
.c-mainmenu.open {

  // NOT HOME
  body:not(.home) & {

    .l-gallery {

      @include breakpoint($medium) {

        p {
          display: block;
        }
      }
    }
  }

  // ALL pages
  @include breakpoint(max-width $max-medium) {
    .l-gallery {
      display: flex;
    }
  }

  // breakpoint $layout only
  @include breakpoint($layout) {
    .l-gallery {
      flex: 0 0 16.5%;
      order: 1;

      &.tall {
        flex-basis: 12%;
        order: 2;
      }

      &.square {
        order: 3;
      }
    }
  }
}