//--- Base styling for Lists ---//

dl, menu, ol, ul {
  font-size: inherit;
  line-height: 1.2;
  margin: 0 0 1rem 0;
  padding: 0 0 0 2rem;
  list-style: disc outside;

  li { margin-bottom: 0.5rem; }

  dl, menu, ol, ul {
    font-size: inherit;
    margin: 0.5rem 0;
    padding: 0 0 0 1rem;
  }
}

ol { list-style-type: decimal; }

dl {
  padding-left: 0;
}

dt {
  margin: 0px;
  font-weight: 700;
}

dd {
  margin: 0 0 0 1rem;
  padding: 0 0 0.5rem 0;
}

nav ol,
nav ul {
  list-style: none;
  list-style-image: none;
  margin: 0;
  padding: 0;
  font-size: inherit;

  li {
    margin: 0;
    padding: 0;
    font-size: inherit;
  }
}