//--- Menu - Text ---//

.l-gallery {

  p {
    font-family: "Bebas";
    letter-spacing: 0.0625rem;  // 1/16th the font size
    margin: 0 auto;
    line-height: 1;
    padding: 0.625rem 0 0.125rem 0.625rem;
    text-align: center;
    font-size: 5vw;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    @include breakpoint($medium-small) {
      font-size: 3vw;
    }

    @include breakpoint($medium) {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      padding: 0;
      text-shadow: 0px 0px 6px $softblack, 0px 0px 2px rgba($black, 0.8);
      font-size: 2vw;

      body.home & {
        font-size: 2.8vw;
        opacity: 0;
        transition: opacity 0.2s ease-in-out;
      }
    }

    @include breakpoint($largest) {
      font-size: 1.75rem;

      body.home & {
        font-size: 2.5rem;
      }
    }
  }

  @include breakpoint($medium) {

    body.home & {

      #{$targeted} {

        p {
          opacity: 1;
        }
      }
    }
  }


  // font styling

  // span should be block
  &#carved,
  &#inhabitants,
  &#sierras,
  &#silt,
  &#structures {

    .desc {
      display: block;
      flex: 1 1 auto;
    }
  }

  &#carved {

    span {
      border-top: 11px double $white;
      margin-top: 2px;
      line-height: 1.8;
      font-size: 82%;

      @include breakpoint($medium-small) {
        font-size: 72%;
      }

      @include breakpoint($medium) {
        font-size: 62%;
      }
    }
  }

  &#inhabitants {

    p {
      font-size: 3.2vw;

      @include breakpoint((strip-unit($medium-small) * 16px) $max-medium) {
        font-size: 2.2vw;
        padding-left: 4px;
        line-height: 1.1;
      }

      @include breakpoint($medium) {
        font-size: 1.2vw;
        width: 110%;

        body.home & {
          font-size: 1.4vw;
        }
      }

      @include breakpoint($largest) {
        font-size: 1.125rem;

        body.home & {
          font-size: 1.25rem;
        }
      }
    }

    span {
      font-size: 220%;
      padding-top: 4px;

      @include breakpoint((strip-unit($medium-small) * 16px) $max-medium) {
        font-size: 160%;
      }
    }
  }

  &#landscapes {

    p {

      @include breakpoint($medium) {
        transform: translate(-50%, 132%);
      }

      @include breakpoint($large) {

        body.home & {
          transform: translate(-50%, 172%);
        }
      }
    }
  }

  &#memorial {

    p {
      font-family: 'Source Sans Pro';
      letter-spacing: 0;
      text-transform: lowercase;
      font-size: 7.8vw;

      @include breakpoint($medium-small) {
        font-size: 3.4vw;
      }

      @include breakpoint($medium) {
        transform: translate(-50%, 144%);

        body.home & {
          transform: translate(-50%, 166%);
        }
      }

      @include breakpoint($largest) {
        font-size: 3rem;
      }

      @include breakpoint($layout) {
        font-size: 2rem;

        body.home & {
          font-size: 3rem;
        }
      }
    }
  }

  // &#metal {}

  &#motion {

    p {
      font-size: 6.2vw;

      @include breakpoint($medium-small) {
        font-size: 4.3vw;
      }

      @include breakpoint($medium) {
        font-size: 2.8vw;

        body.home & {
          font-size: 3.2vw;
        }
      }

      @include breakpoint($largest) {
        font-size: 2.5rem;

        body.home & {
          font-size: 2.875rem;
        }
      }

      @include breakpoint($layout) {
        font-size: 2.125rem;

        body.home & {
          font-size: 2.875rem;
        }
      }
    }
  }

  &#sierras {

    p {
      font-size: 8.2vw;

      @include breakpoint($medium-small) {
        font-size: 5vw;
      }

      @include breakpoint($medium) {
        font-size: 4vw;
        transform: translate(-50%, -45%);
      }

      @include breakpoint($large) {
        transform: translate(-50%, -29%);
      }

      @include breakpoint($largest) {
        font-size: 3.625rem;
      }

      @include breakpoint($layout) {
        font-size: 2.625rem;

        body.home & {
          font-size: 3.625rem;
        }
      }
    }

    span {
      font-size: 35%;
      padding-top: 4px;

      @include breakpoint($full) {
        font-size: 24%
      }
    }
  }

  &#silt {

    p {
      font-size: 7.6vw;

      @include breakpoint($medium-small) {
        font-size: 3.6vw;
      }

      @include breakpoint($medium) {
       font-size: 2.8vw;
        width: 110%;

        body.home & {
          font-size: 3.2vw;
        }
     }

      @include breakpoint($largest) {
        font-size: 2.5rem;

        body.home & {
          font-size: 2.875rem;
        }
      }
   }

    span {
      font-size: 46%;
    }
  }

  &#small {

    p {
      font-size: 3.4vw;

      @include breakpoint($medium-small) {
        font-size: 2.6vw;
      }

      @include breakpoint($medium) {
        font-size: 1.6vw;
        width: 100%;
        transform: translate(-50%, -20%);
      }

      @include breakpoint($largest) {
        font-size: 1.375rem;
      }
    }
  }

  &#structures {

    p {
      font-size: 3.2vw;

      @include breakpoint((strip-unit($medium-small) * 16px) $max-medium) {
        font-size: 2.2vw;
        padding-left: 4px;
        line-height: 1.1;
      }

      @include breakpoint($medium) {
        font-size: 1.4vw;
        width: 110%;

        body.home & {
          font-size: 3.1vw;
        }
      }

      @include breakpoint($largest) {
        font-size: 1.25rem;

        body.home & {
          font-size: 2.75rem;
        }
      }
    }

    span {
      font-size: 220%;
      padding-top: 4px;

      @include breakpoint((strip-unit($medium-small) * 16px) $max-medium) {
        font-size: 180%;
      }
    }
  }

  &#textures {

    p {
      font-size: 5vw;
      line-height: 1.2;

      @include breakpoint($medium-small) {
        font-size: 4vw;
      }

      @include breakpoint($medium) {
        font-size: 3vw;
        line-height: 1.6;
      }

      @include breakpoint($largest) {
        font-size: 2.625rem;
      }

      @include breakpoint($layout) {
        font-size: 1.875rem;

        body.home & {
          font-size: 2.625rem;
        }
      }
    }

    span {
      flex: 0 1 auto;
      padding: 0 0.25em;

      @include breakpoint($medium-small) {
        flex: 1 0 100%;
      }
    }
  }
}