//--- Carousel Main Imgs and Thumbs ---//
//  ul.carousel.gallery
//  ul.carousel.thumbs

.carousel {
  list-style: none;
  margin: $vertical-rhythm ($vertical-rhythm * 2);
  padding: 0;

  display: flex;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
  flex-wrap: nowrap;

  overflow: hidden;
  position: relative;
}

.carousel-seat {
  text-align: center;
  flex: 1 0 100%;
  order: 2;
}
