// Mixins

// Note: some specific-use mixins are in their respective files
// for lists, see _base.lists
// for buttons, see _base.buttons
// for fonts, see _settings.font_definitions

@mixin clearfix {
  &:before,
  &:after {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
  }
}

// list resets
@mixin list-bare {
  list-style: none;
  margin: 0;
  padding: 0;
}
@mixin list-clean {
  margin: 0;
  padding: 0;
}

// page layout
@mixin positioning {
  padding: $vertical-rhythm $lr;

  @include breakpoint($layout) {
    padding: $vertical-rhythm 0;// !important;

    > * {
      max-width: $maxwidth;
      margin: 0 auto;
    }
  }
}

//-- Convert rem/em to px --//
// use when the variable HAS A STRING
//
// common usage for breakpoints:
// this won't work: @include breakpoint($mobile-toggle $max-medium);
// because $mobile-toggle is defined as em, and $max-medium as px
// but this does: @include breakpoint((strip-unit($mobile-toggle) * 16px) $max-medium)
//
// here is sample variable usage (to keep the breakpoint declaration clean & easy to read:
// $mobile-breakpoint: strip-unit($medium) * 16px;
// which would result in this: @include breakpoint($mobile-breakpoint $max-medium)
//
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }

  @return $number;
}
// note: I need to update sass, so I can make a mixin:
// https://stackoverflow.com/questions/14295595/pass-function-or-mixin-by-reference-in-sass


// too complicated, but keeping for reference
//-- Viewport Width Calculations --//
// https://www.smashingmagazine.com/2016/05/fluid-typography/
// https://www.sassmeister.com/gist/7f22e44ace49b5124eec
@mixin fluid-type($properties, $min-vw, $max-vw, $min-value, $max-value) {
  @each $property in $properties {
    #{$property}: $min-value;
  }

  @media (min-width: $min-vw) {
    @each $property in $properties {
      #{$property}: calc(#{$min-value} + #{strip-unit($max-value - $min-value)} * (100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)});
    }
  }

  @media (min-width: $max-vw) {
    @each $property in $properties {
      #{$property}: $max-value;
    }
  }
}
// Usage:
//
// Single property
// html {
//   @include fluid-type(font-size, 320px, 1408px, 1rem, 2.25rem);
// }
//
// Multiple properties with same values
// h1 {
//   @include fluid-type(padding-bottom padding-top, 20em, 70em, 2em, 4em);
// }
//