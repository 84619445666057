//--- Footer ---//

footer {
  background-color: $midblack;
  font-size: 0.875rem;
  color: $grey;
  text-align: center;

  @include positioning;

  .l-contact.button {
    @include breakpoint($medium-small) {
      float: right;
      margin-right: 0;
    }
  }

  .copyright {
    margin-bottom: 0.5rem;
  }

  .legal {

    a {
      padding: 0 0.5rem;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }

      &:not(:last-child) {
        border-right: 1px solid $grey;
      }

      #{$linked} {
        color: $lightblack;
      }

      #{$targeted} {
        color: $yellow;
      }
    }
  }
}