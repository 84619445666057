//--- Menu ---//

.c-mainmenu {
  z-index: 99;
  // mobile has no additional layout
  padding: 0 $lr;

  @include breakpoint((strip-unit($medium) * 16px) $max-layout) {
    padding: 0 1%;
  }

  // ON HOME all sizes have vertical padding
  // medium HOME has layout
  body.home & {
    padding-top: $vertical-rhythm;
    padding-bottom: $vertical-rhythm;

    @include breakpoint(max-width $max-medium) {
      background-color: $softblack;
    }

    @include breakpoint($medium) {
      padding: $vertical-rhythm $lr;
    }
  }

  // NOT on HOME
  // desktop always has vertical padding
  // mobile OPEN has vertical padding
  // medium GALLERY has no additonal layout
  body:not(.home) & {
    background-color: $softblack;

    &:not(.open) {
      flex: 0 1 0vw;
      padding: 0;

      & ~ .board {
        flex: 1 0 100%;
      }

      a:not(.menu-buttons) {
        display: none;
      }
    }

    &.open {
      $border: 2px solid $black;
      flex: 1 0 20vw;
      padding-top: $vertical-rhythm;
      padding-bottom: $vertical-rhythm;
      border-bottom: $border;

      @include breakpoint((strip-unit($medium) * 16px) $max-layout) {
        border-right: $border;
        border-bottom: 0px;
      }
    }
  }

  // use .wrapper to apply flexbox, margins to the links
  .wrapper {

    @include breakpoint((strip-unit($medium-small) * 16px) $max-medium) {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: stretch;

      a {
        flex: 0 0 48%;
        max-width: 48%;

        &:last-of-type {
          margin-left: 0;
          margin-right: 0;
        }
      }
    }

    @include breakpoint($layout) {
      position: relative;
      max-width: $maxwidth;
      margin: 0 auto;

      body:not(.home) & {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-content: center;
        align-items: center;

        // these sizes are for non-open state, if visible.
        // kept for reference only, or if the menu is converted back.
        // active specifications are in menu.links.active
        > a {
          // flex: 0 0 9%;
          order: 1;
        }

        .tall {
          // flex-basis: 5.25%;
          order: 2;
        }

        .square {
          // flex-basis: 7%;
          order: 3;
        }
      }
    }
  }
}