//-- Carousel Board --//
// Full Carousel container

// board
//  div
//    carousel.gallery
//      carousel-seat
//    controls
//  div
//    carousel.thumbs
//      carousel-seat
//    pagination
//    controls.thumbs

.board {
  overflow: hidden;
  background-color: rgba($white, 0.86);
  flex: 1 1 auto;

  @include positioning;

  > * {
    @include breakpoint(max-width $max-medium) {
      margin: 0 $lr;
    }
  }

  div {
    position: relative;
  }

  // selected text
  &::selection,
  *::selection {
    background-color: transparent;
  }
}
