// ------ Buttons ------ //

@mixin make-button($background, $color) {
  transition: background-color 0.2s ease-in-out, color 0.3s ease-in-out;
  $dark-background: darken($background, 9.4%);
  background-color: $background;
  color: $color;
  padding: 0.375rem 0.75rem;
  margin: 0 0.25rem 0.5rem 0;
  line-height: 1.25;
  position: relative;
  text-decoration: none;
  text-align: center;
  display: inline-block;
  letter-spacing: 0.4px;
  cursor: pointer;
  border: 1px solid $dark-background;
  box-shadow: 0px 0px 6px 1px rgba($dark-background, 0.5) inset;
  font-size: 1rem;
  text-transform: capitalize;

  @include breakpoint($medium) {
    padding: 0.125rem 0.75rem;
  }

  &:only-child { margin-bottom: 0; }

  #{$linked} {
    color: $color;
  }

  #{$targeted} {
    background: $dark-background;
    text-decoration: none;
    color: $color !important;
  }
}

input[type="button"],
input[type="submit"],
button,
.button, .submit {
  @include make-button($blue, $white);
}